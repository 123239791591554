import styled from "@emotion/styled";
import type { ButtonHTMLAttributes } from "react";

import { alpha, color } from "metabase/lib/colors";
import type { ButtonProps } from "metabase/ui";
import { Button } from "metabase/ui";

type FilterButton = ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonProps & {
    isCollapsed: boolean;
  };

export const FilterButton = styled(Button)<FilterButton>`
  color: ${({ isCollapsed }) =>
    isCollapsed ? color("white") : color("filter")};
  background-color: ${({ isCollapsed }) =>
    isCollapsed ? alpha("filter", 0.8) : alpha("filter", 0.2)};
  transition: border 300ms linear, background 300ms linear;
  margin: 0.5em 0;
  margin-right: 10px;
  overflow: visible;

  &:hover {
    color: ${color("white")};
    background-color: ${color("filter")};
  }

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;
