import styled from "@emotion/styled";

import Input from "metabase/core/components/Input";

export const ChartSettingIntegerNumeric = styled(Input)`
  display: block;

  ${Input.Field} {
    width: 100%;
  }
`;
