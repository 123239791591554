import { getIn } from "icepick";
import { t } from "ttag";
import _ from "underscore";

import { formatValue, formatColumn } from "metabase/lib/formatting";
import { makeCellStyleGetter } from "metabase/visualizations/lib/table_format";

export function isPivotGroupColumn(col) {
  return col.name === "pivot-grouping";
}

export const COLUMN_FORMATTING_SETTING = "table.column_formatting";
export const COLLAPSED_ROWS_SETTING = "pivot_table.collapsed_rows";
export const COLUMN_SPLIT_SETTING = "pivot_table.column_split";
export const COLUMN_SHOW_TOTALS = "pivot_table.column_show_totals";
export const COLUMN_SORT_ORDER = "pivot_table.column_sort_order";
export const COLUMN_SORT_ORDER_ASC = "ascending";
export const COLUMN_SORT_ORDER_DESC = "descending";
export const HIDDEN_COLUMNS_SETTING = "pivot_table.hidden_columns";

export function multiLevelPivot(data, settings) {
  const columnSplit = settings[COLUMN_SPLIT_SETTING];
  const isHierarchy = settings["pivot_table.hierarchy"];
  if (columnSplit == null) {
    return null;
  }
  const columnsWithoutPivotGroup = data.cols.filter(
    col => !isPivotGroupColumn(col),
  );

  const hiddenFieldRefs = settings[HIDDEN_COLUMNS_SETTING];

  const {
    columns: columnColumnIndexes,
    rows: rowColumnIndexes,
    values: valueColumnIndexes,
  } = _.mapObject(columnSplit, columnFieldRefs =>
    columnFieldRefs
      .map(field_ref =>
        columnsWithoutPivotGroup.findIndex(col => {
          const hide =
            hiddenFieldRefs.find(field_ref =>
              _.isEqual(col.field_ref, field_ref),
            ) !== undefined;
          return hide ? false : _.isEqual(col.field_ref, field_ref);
        }),
      )
      .filter(index => index !== -1),
  );

  const { pivotData, columns } = splitPivotData(data);
  const columnSettings = columns.map(column => settings.column(column));
  const allCollapsedSubtotals = settings[COLLAPSED_ROWS_SETTING].value;
  const collapsedSubtotals = filterCollapsedSubtotals(
    allCollapsedSubtotals,
    rowColumnIndexes.map(index => columnSettings[index]),
  );

  // we build a tree for each tuple of pivoted column/row values seen in the data
  const columnColumnTree = [];
  const rowColumnTree = [];

  // this stores pivot table values keyed by all pivoted columns
  const valuesByKey = {};

  // loop over the primary rows to build trees of column/row header data
  const primaryRowsKey = JSON.stringify(
    _.range(columnColumnIndexes.length + rowColumnIndexes.length),
  );
  for (const row of pivotData[primaryRowsKey]) {
    // mutate the trees to add the tuple from the current row
    updateValueObject(
      row,
      columnColumnIndexes,
      columnSettings,
      columnColumnTree,
    );
    updateValueObject(
      row,
      rowColumnIndexes,
      columnSettings,
      rowColumnTree,
      collapsedSubtotals,
      isHierarchy,
    );

    // save the value columns keyed by the values in the column/row pivoted columns
    const valueKey = JSON.stringify(
      columnColumnIndexes.concat(rowColumnIndexes).map(index => row[index]),
    );
    const values = valueColumnIndexes.map(index => row[index]);
    const valueColumns = valueColumnIndexes.map(
      index => columnSettings[index]?.column,
    );

    valuesByKey[valueKey] = {
      values,
      valueColumns,
      data: row.map((value, index) => ({ value, col: columns[index] })),
      dimensions: row
        .map((value, index) => ({
          value,
          column: columns[index],
        }))
        .filter(({ column }) => column.source === "breakout"),
    };
  }

  // build objects to look up subtotal values
  const subtotalValues = {};
  for (const [subtotalName, subtotal] of Object.entries(pivotData)) {
    const indexes = JSON.parse(subtotalName);
    subtotalValues[subtotalName] = {};
    for (const row of subtotal) {
      const valueKey = JSON.stringify(indexes.map(index => row[index]));
      subtotalValues[subtotalName][valueKey] = valueColumnIndexes.map(
        index => row[index],
      );
    }
  }

  // pivot tables have a lot of repeated values, so we use memoized formatters for each column
  const [valueFormatters, topIndexFormatters, leftIndexFormatters] = [
    valueColumnIndexes,
    columnColumnIndexes,
    rowColumnIndexes,
  ].map(indexes =>
    indexes.map(index =>
      _.memoize(
        value => formatValue(value, columnSettings[index]),
        value => [value, index].join(),
      ),
    ),
  );

  const topIndexColumns = columnColumnIndexes.map(index => columns[index]);
  const formattedColumnTreeWithoutValues = formatValuesInTree(
    columnColumnTree,
    topIndexFormatters,
    topIndexColumns,
  );
  if (
    formattedColumnTreeWithoutValues.length > 1 &&
    settings["pivot.show_row_totals"]
  ) {
    // if there are multiple columns, we should add another for row totals
    formattedColumnTreeWithoutValues.push({
      value: t`Row totals`,
      children: [],
      isSubtotal: true,
      isGrandTotal: true,
    });
  }

  const columnIndex = addEmptyIndexItem(
    formattedColumnTreeWithoutValues.flatMap(root => enumeratePaths(root)),
  );
  const valueColumns = valueColumnIndexes.map(index => [
    columns[index],
    columnSettings[index],
  ]);
  const formattedColumnTree = addValueColumnNodes(
    formattedColumnTreeWithoutValues,
    valueColumns,
  );

  const leftIndexColumns = rowColumnIndexes.map(index => columns[index]);
  const formattedRowTreeWithoutSubtotals = formatValuesInTree(
    rowColumnTree,
    leftIndexFormatters,
    leftIndexColumns,
  );
  const initialLeftHeaderTree = treeToArray(formattedRowTreeWithoutSubtotals);
  const showSubtotalsByColumn = rowColumnIndexes.map(
    index => getIn(columnSettings, [index, COLUMN_SHOW_TOTALS]) !== false,
  );

  const formattedRowTree = settings["pivot.show_column_totals"]
    ? addSubtotals(
        formattedRowTreeWithoutSubtotals,
        showSubtotalsByColumn,
        isHierarchy,
      )
    : formattedRowTreeWithoutSubtotals;

  if (
    formattedRowTreeWithoutSubtotals.length > 1 &&
    settings["pivot.show_column_totals"]
  ) {
    // if there are multiple columns, we should add another for row totals
    formattedRowTree.push({
      value: t`Grand totals`,
      isSubtotal: true,
      isGrandTotal: true,
      children: [],
    });
  }

  const initialRowIndex = addEmptyIndexItem(
    formattedRowTree.flatMap(root => enumeratePaths(root)),
  );

  const set = new Set();
  initialRowIndex.forEach(element => set.add(JSON.stringify(element)));
  const result = [...set];
  const unparsed = result.map(element => JSON.parse(element));
  const filteredUnparsed = unparsed.filter(
    element => !makeCollapsedChildren(allCollapsedSubtotals, element),
  );

  const rowIndex = isHierarchy ? filteredUnparsed : initialRowIndex;

  const spliceSubtotal = initialRow => {
    const hierarchyRow = [];
    initialRow.forEach(element => {
      if (element.isSubtotal !== true || element.isGrandTotal === true) {
        if (element.children.length > 0) {
          element.children = spliceSubtotal(element.children);
          hierarchyRow.push(element);
        } else {
          hierarchyRow.push(element);
        }
      }
    });
    return hierarchyRow;
  };
  const leftHeaderItems = isHierarchy
    ? treeToArray(spliceSubtotal(formattedRowTree).flat(), isHierarchy)
    : treeToArray(formattedRowTree.flat());
  const topHeaderItems = treeToArray(formattedColumnTree.flat());

  const backgroundColorGetter = makeCellStyleGetter(
    pivotData[primaryRowsKey],
    columns,
    settings["table.column_formatting"].filter(
      rule => rule.target === "background",
    ) ?? [],
  );

  const subtotalDataUnfiltered = pivotData[primaryRowsKey] || [];
  const subtotalData = subtotalDataUnfiltered.map(arr =>
    arr.filter(item => item !== null),
  );
  const subtotalColumns = columns.filter((col, index) => {
    if (index === 0) {
      return true;
    } else {
      if (col.source === "breakout") {
        return false;
      } else {
        return true;
      }
    }
  });

  const leftColumnsWithCorrespondingValues = leftHeaderItems.map(item => {
    const indexKey = getArrayKeyByLength(
      leftIndexColumns.length - 1 - item.maxDepthBelow,
    );
    const pathKey = JSON.stringify(item.path);

    let subtotalList = subtotalValues?.[indexKey]?.[pathKey] || [];
    if (!subtotalValues?.[indexKey]?.[pathKey]) {
      const subtotalValuesKeys = Object.keys(subtotalValues);
      for (const key of subtotalValuesKeys) {
        const target = subtotalValues[key][pathKey];
        if (target) {
          subtotalList = target;
          break;
        }
      }
    }

    const leftSectionDataList = new Array(leftIndexColumns.length).fill(null);
    const path = [...(item.path || [])];
    while (path.length < leftIndexColumns.length) {
      path.push(null);
    }

    leftSectionDataList[leftIndexColumns.length - 1 - item.maxDepthBelow] =
      item.rawValue;

    if (!item.column) {
      item.columnForTotal =
        columns?.[rowColumnIndexes?.[item?.path?.length - 1]];
    }

    return [...path, ...subtotalList];
  });

  const rowsAndValuesCols = [...rowColumnIndexes, ...valueColumnIndexes].map(
    colIndex => columns[colIndex],
  );

  const backgroundColorGetterForLeftSection = makeCellStyleGetter(
    leftColumnsWithCorrespondingValues,
    rowsAndValuesCols,
    settings["table.column_formatting"].filter(
      rule => rule.target === "background",
    ) ?? [],
  );

  const backgroundColorGetterForSubtotal = makeCellStyleGetter(
    subtotalData,
    subtotalColumns,
    settings["table.column_formatting"].filter(
      rule => rule.target === "background",
    ) ?? [],
  );

  const textColorGetter = makeCellStyleGetter(
    pivotData[primaryRowsKey],
    columns,
    settings["table.column_formatting"].filter(
      rule => rule.target === "text",
    ) ?? [],
  );

  const fontStyleGetter = makeCellStyleGetter(
    pivotData[primaryRowsKey],
    columns,
    settings["table.column_formatting"].filter(
      rule => rule.target === "font",
    ) ?? [],
  );

  const getRowSection = createRowSectionGetter({
    valuesByKey,
    subtotalValues,
    valueFormatters,
    columnColumnIndexes,
    rowColumnIndexes,
    columnIndex,
    rowIndex,
    backgroundColorGetter,
    textColorGetter,
    fontStyleGetter,
    valueColumns: valueColumnIndexes.map(
      index => columnSettings[index]?.column,
    ),
    rowsWithoutSubtotals: pivotData[primaryRowsKey],
    subtotalData,
    backgroundColorGetterForSubtotal,
  });

  const getLeftSection = createLeftSectionGetter({
    leftHeaderItems,
    backgroundColorGetter: backgroundColorGetterForLeftSection,
    textColorGetter,
    fontStyleGetter,
    columns,
  });

  return {
    leftHeaderItems,
    topHeaderItems,
    rowCount: rowIndex.length,
    columnCount: columnIndex.length,
    rowIndex,
    getRowSection,
    getLeftSection,
    rowIndexes: rowColumnIndexes,
    columnIndexes: columnColumnIndexes,
    valueIndexes: valueColumnIndexes,
    initialLeftHeaderTree,
  };
}

// This pulls apart the different aggregations that were packed into one result set.
// There's a column indicating which breakouts were used to compute that row.
// We use that column to split apart the data and convert the field refs to indexes.
function splitPivotData(data) {
  const groupIndex = data.cols.findIndex(isPivotGroupColumn);
  const columns = data.cols.filter(col => !isPivotGroupColumn(col));
  const breakouts = columns.filter(col => col.source === "breakout");

  const pivotData = _.chain(data.rows)
    .groupBy(row => row[groupIndex])
    .pairs()
    .map(([key, rows]) => {
      key = parseInt(key);
      const indexes = _.range(breakouts.length).filter(
        index => !((1 << index) & key),
      );
      const keyAsIndexes = JSON.stringify(indexes);
      const rowsWithoutColumn = rows.map(row =>
        row.slice(0, groupIndex).concat(row.slice(groupIndex + 1)),
      );

      return [keyAsIndexes, rowsWithoutColumn];
    })
    .object()
    .value();
  return { pivotData, columns };
}

function addEmptyIndexItem(index) {
  // we need a single item even if all columns are on the other axis
  return index.length === 0 ? [[]] : index;
}

// A path can't be collapsed if subtotals are turned off for that column.
// TODO: can we move this to the COLLAPSED_ROW_SETTING itself?
function filterCollapsedSubtotals(collapsedSubtotals, columnSettings) {
  const columnIsCollapsible = columnSettings.map(
    settings => settings[COLUMN_SHOW_TOTALS] !== false,
  );
  return collapsedSubtotals.filter(pathOrLengthString => {
    const pathOrLength = JSON.parse(pathOrLengthString);
    const length = Array.isArray(pathOrLength)
      ? pathOrLength.length
      : pathOrLength;
    return columnIsCollapsible[length - 1];
  });
}

function createLeftSectionGetter({
  leftHeaderItems,
  backgroundColorGetter,
  textColorGetter,
  fontStyleGetter,
}) {
  const getter = index => {
    const colName = leftHeaderItems[index]?.column?.name;

    const backgroundColor = backgroundColorGetter(
      leftHeaderItems[index].rawValue,
      index,
      colName,
    );

    const textColor = textColorGetter(
      leftHeaderItems[index].rawValue,
      index,
      colName,
    );

    const fontStyle = fontStyleGetter(
      leftHeaderItems[index].rawValue,
      index,
      colName,
    );

    return {
      item: leftHeaderItems[index],
      ...(backgroundColor ? { backgroundColor } : {}),
      ...(textColor ? { textColor } : {}),
      ...(fontStyle ? { fontStyle } : {}),
    };
  };

  return _.memoize(getter, i1 => [i1].join());
}

// The getter returned from this function returns the value(s) at given (column, row) location
function createRowSectionGetter({
  valuesByKey,
  subtotalValues,
  valueFormatters,
  columnColumnIndexes,
  rowColumnIndexes,
  columnIndex,
  rowIndex,
  backgroundColorGetter,
  textColorGetter,
  fontStyleGetter,
  valueColumns,
  rowsWithoutSubtotals = null,
  subtotalData = null,
  backgroundColorGetterForSubtotal = null,
}) {
  // Unboxing values from object with field value
  const formatValues = values =>
    values === undefined
      ? Array(valueFormatters.length).fill({ value: null })
      : values.map((v, i) => ({
          value: valueFormatters[i](v),
          rawValue: v,
        }));

  const getSubtotals = (breakoutIndexes, values, otherAttrs) =>
    formatValues(
      getIn(
        subtotalValues,
        [breakoutIndexes, values].map(a =>
          JSON.stringify(
            _.sortBy(a, (_value, index) => breakoutIndexes[index]),
          ),
        ),
      ),
    ).map((value, index, arr) => {
      let backgroundColor = null;
      let textColor = null;
      // Get indexes to correct color row highlight
      if (backgroundColorGetterForSubtotal !== null && subtotalData !== null) {
        const flatArr = arr.map(item => item.rawValue).filter(item => item);
        const rowIndexForColor = subtotalData.findIndex(row =>
          flatArr.every(
            value =>
              row.find(item => {
                return value === item;
              }) !== undefined,
          ),
        );

        if (rowIndexForColor > 0) {
          backgroundColor = backgroundColorGetterForSubtotal(
            value.rawValue,
            rowIndexForColor,
            valueColumns[index].name,
          );

          textColor = textColorGetter(
            value.rawValue,
            rowIndexForColor,
            valueColumns[index].name,
          );
        }
      }

      // Totals rows
      return {
        ...value,
        isSubtotal: true,
        ...otherAttrs,
        ...(backgroundColor ? { backgroundColor } : {}),
        ...(textColor ? { textColor } : {}),
      };
    });

  const getter = (columnIdx, rowIdx) => {
    const columnValues = columnIndex[columnIdx] || [];
    const rowValues = rowIndex[rowIdx] || [];
    const indexValues = columnValues.concat(rowValues);
    if (
      rowValues.length < rowColumnIndexes.length ||
      columnValues.length < columnColumnIndexes.length
    ) {
      // if we don't have a full-length key, we're looking for a subtotal
      const rowIndexes = rowColumnIndexes.slice(0, rowValues.length);
      const columnIndexes = columnColumnIndexes.slice(0, columnValues.length);
      const indexes = columnIndexes.concat(rowIndexes);
      const otherAttrs = rowValues.length === 0 ? { isGrandTotal: true } : {};
      return getSubtotals(indexes, indexValues, otherAttrs);
    }
    const { values, data, dimensions, valueColumns } =
      valuesByKey[JSON.stringify(indexValues)] || {};

    // Get indexes to correct color row highlight
    let rowIndexForColor = -1;
    if (rowsWithoutSubtotals !== null && values) {
      const findRow = [...indexValues, ...values];
      rowIndexForColor = rowsWithoutSubtotals.findIndex(row =>
        findRow.every(value => row.find(item => value === item) !== undefined),
      );
    }

    return formatValues(values).map((o, index) => {
      let backgroundColor = null;
      let textColor = null;
      let fontStyle = null;
      if (rowIndexForColor !== -1) {
        backgroundColor = backgroundColorGetter(
          o.rawValue,
          rowIndexForColor,
          valueColumns[index].name,
        );

        textColor = textColorGetter(
          o.rawValue,
          rowIndexForColor,
          valueColumns[index].name,
        );

        fontStyle = fontStyleGetter(
          o.rawValue,
          rowIndexForColor,
          valueColumns[index].name,
        );
      }

      // Simple row
      return data === undefined
        ? o
        : {
            ...o,
            clicked: { data, dimensions },
            ...(backgroundColor ? { backgroundColor } : {}),
            ...(textColor ? { textColor } : {}),
            ...(fontStyle ? { fontStyle } : {}),
          };
    });
  };
  return _.memoize(getter, (i1, i2) => [i1, i2].join());
}

// Given a tree representation of an index, enumeratePaths produces a list of all paths to leaf nodes
function enumeratePaths(
  { rawValue, isGrandTotal, children, isValueColumn },
  path = [],
) {
  if (isGrandTotal) {
    return [[]];
  }
  if (isValueColumn) {
    return [path];
  }
  const pathWithValue = [...path, rawValue];
  return children.length === 0
    ? [pathWithValue]
    : children.flatMap(child => enumeratePaths(child, pathWithValue));
}

function formatValuesInTree(
  rowColumnTree,
  [formatter, ...formatters],
  [column, ...columns],
) {
  return rowColumnTree.map(({ value, children, ...rest }) => ({
    ...rest,
    value: formatter(value),
    column,
    rawValue: value,
    children: formatValuesInTree(children, formatters, columns),
    clicked: { value, column, data: [{ value, col: column }] },
  }));
}

// This might add value column(s) to the bottom of the top header tree.
// We display the value column names if there are multiple
// or if there are no columns pivoted to the top header.
function addValueColumnNodes(nodes, valueColumns) {
  const leafNodes = valueColumns.map(([column, columnSettings]) => {
    return {
      value: columnSettings.column_title || formatColumn(column),
      children: [],
      isValueColumn: true,
    };
  });
  if (nodes.length === 0) {
    return leafNodes;
  }
  if (valueColumns.length <= 1) {
    return nodes;
  }

  function updateNode(node) {
    const children =
      node.children.length === 0 ? leafNodes : node.children.map(updateNode);
    return { ...node, children };
  }

  return nodes.map(updateNode);
}

// This inserts nodes into the left header tree for subtotals.
// We also mark nodes with `hasSubtotal` to display collapsing UI
function addSubtotals(rowColumnTree, showSubtotalsByColumn, isHierarchy) {
  // For top-level items we want to show subtotal even if they have only one child
  // Except the case when top-level items have flat structure
  // (meaning all of the items have just one child)
  // If top-level items are flat, subtotals will just repeat their corresponding row
  // https://github.com/metabase/metabase/issues/15211
  // https://github.com/metabase/metabase/pull/16566
  const notFlat = rowColumnTree.some(
    item => item.children.length > (isHierarchy ? 0 : 1),
  );

  return rowColumnTree.flatMap(item => {
    const shouldShowSubtotal = notFlat || item.children.length > 1;
    return addSubtotal(
      item,
      showSubtotalsByColumn,
      shouldShowSubtotal,
      isHierarchy,
    );
  });
}

function addSubtotal(
  item,
  [isSubtotalEnabled, ...showSubtotalsByColumn],
  shouldShowSubtotal,
  isHierarchy,
) {
  const hasSubtotal = isSubtotalEnabled && shouldShowSubtotal;
  const subtotal = hasSubtotal
    ? [
        {
          value: t`Totals for ${item.value}`,
          rawValue: item.rawValue,
          span: 1,
          isSubtotal: true,
          children: [],
        },
      ]
    : [];
  if (item.isCollapsed) {
    return subtotal;
  }
  const node = {
    ...item,
    hasSubtotal,
    children: item.children.flatMap(child => {
      const childrenShouldShowSubtotal =
        child.children.length > (isHierarchy ? 0 : 1) || child.isCollapsed;
      // add subtotals until the last level
      return child.children.length > 0
        ? addSubtotal(
            child,
            showSubtotalsByColumn,
            childrenShouldShowSubtotal,
            isHierarchy,
          )
        : isHierarchy && child.isCollapsed
        ? []
        : child;
    }),
  };

  return isHierarchy ? [...subtotal, node] : [node, ...subtotal];
}

// Update the tree with a row of data
function updateValueObject(
  row,
  indexes,
  columnSettings,
  seenValues,
  collapsedSubtotals = [],
  isHierarchy,
) {
  let currentLevelSeenValues = seenValues;
  const prefix = [];
  for (const index of indexes) {
    const value = row[index];
    prefix.push(value);
    let seenValue = currentLevelSeenValues.find(d => d.value === value);
    const isCollapsed =
      // the specific path is collapsed
      (isHierarchy
        ? makeCollapsedChildren(collapsedSubtotals, prefix)
        : collapsedSubtotals.includes(JSON.stringify(prefix))) ||
      // the entire column is collapsed
      collapsedSubtotals.includes(JSON.stringify(prefix.length));
    if (seenValue === undefined) {
      seenValue = { value, children: [], isCollapsed };
      currentLevelSeenValues.push(seenValue);
      sortLevelOfTree(currentLevelSeenValues, columnSettings[index]);
    }
    currentLevelSeenValues = seenValue.children;
  }
}

// Sorts the array of nodes in place if a sort order is set for that column
function sortLevelOfTree(array, { [COLUMN_SORT_ORDER]: sortOrder } = {}) {
  if (sortOrder == null) {
    // don't sort unless there's a column sort order set
    return;
  }
  array.sort((a, b) => {
    if (a.value === b.value) {
      return 0;
    }
    // by default use "<" to compare values
    let result = a.value < b.value ? -1 : 1;
    // strings should use localeCompare to handle accents, etc
    if (typeof a.value === "string") {
      result = a.value.localeCompare(b.value);
    }
    // flip the comparison for descending
    if (sortOrder === COLUMN_SORT_ORDER_DESC) {
      result *= -1;
    }
    return result;
  });
}

// Take a tree and produce a flat list used to layout the top/left headers.
// We track the depth, offset, etc to know how to line items up in the headers.
function treeToArray(nodes, isHierarchy) {
  const a = [];
  let index = 0;
  let currentOffset = 0;

  function dfs(nodes, depth, offset, path = []) {
    if (nodes.length === 0) {
      return { span: 1, maxDepth: 0 };
    }
    let totalSpan = 0;
    let maxDepth = 0;
    for (const {
      children,
      rawValue,
      isGrandTotal,
      isValueColumn,
      isSubtotal,
      ...rest
    } of nodes) {
      const pathWithValue =
        isValueColumn || isGrandTotal ? null : [...path, rawValue];
      let indexOfCorrespondingRowData;
      if (children.length > 1) {
        indexOfCorrespondingRowData = undefined;
      } else if (children.length === 1) {
        indexOfCorrespondingRowData = index;
      } else {
        indexOfCorrespondingRowData = index;
        index++;
      }
      const item = {
        ...rest,
        rawValue,
        isGrandTotal,
        depth,
        offset: isHierarchy ? currentOffset : offset,
        hasChildren: children.length > 0,
        path: pathWithValue,
        indexOfCorrespondingRowData,
      };
      a.push(item);
      isHierarchy && currentOffset++;
      const result = dfs(children, depth + 1, offset, pathWithValue);
      item.span = result.span;
      item.maxDepthBelow = result.maxDepth;
      offset += result.span;
      totalSpan += result.span;
      maxDepth = Math.max(maxDepth, result.maxDepth);
    }
    return isHierarchy
      ? { span: 1, maxDepth: maxDepth + 1 }
      : { span: totalSpan, maxDepth: maxDepth + 1 };
  }

  dfs(nodes, 0, 0);
  return a;
}

// This is the pivot function used in the normal table visualization.
export function pivot(data, normalCol, pivotCol, cellCol) {
  const { pivotValues, normalValues } = distinctValuesSorted(
    data.rows,
    pivotCol,
    normalCol,
  );

  // make sure that the first element in the pivoted column list is null which makes room for the label of the other column
  pivotValues.unshift(data.cols[normalCol].display_name);

  // start with an empty grid that we'll fill with the appropriate values
  const pivotedRows = normalValues.map((normalValues, index) => {
    const row = pivotValues.map(() => null);
    // for onVisualizationClick:
    row._dimension = {
      value: normalValues,
      column: data.cols[normalCol],
    };
    return row;
  });

  // keep a record of which row the data came from for onVisualizationClick
  const sourceRows = normalValues.map(() => pivotValues.map(() => null));

  // fill it up with the data
  for (let j = 0; j < data.rows.length; j++) {
    const normalColIdx = normalValues.lastIndexOf(data.rows[j][normalCol]);
    const pivotColIdx = pivotValues.lastIndexOf(data.rows[j][pivotCol]);

    pivotedRows[normalColIdx][0] = data.rows[j][normalCol];
    pivotedRows[normalColIdx][pivotColIdx] = data.rows[j][cellCol];
    sourceRows[normalColIdx][pivotColIdx] = j;
  }

  // provide some column metadata to maintain consistency
  const cols = pivotValues.map(function (value, idx) {
    if (idx === 0) {
      // first column is always the coldef of the normal column
      return data.cols[normalCol];
    } else {
      return {
        ...data.cols[cellCol],
        // `name` must be the same for conditional formatting, but put the
        // formatted pivotted value in the `display_name`
        display_name: formatValue(value, { column: data.cols[pivotCol] }) || "",
        // for onVisualizationClick:
        _dimension: {
          value: value,
          column: data.cols[pivotCol],
        },
      };
    }
  });

  return {
    cols: cols,
    columns: pivotValues,
    rows: pivotedRows,
    sourceRows,
  };
}

function distinctValuesSorted(rows, pivotColIdx, normalColIdx) {
  const normalSet = new Set();
  const pivotSet = new Set();

  const normalSortState = new SortState();
  const pivotSortState = new SortState();

  for (const row of rows) {
    const pivotValue = row[pivotColIdx];
    const normalValue = row[normalColIdx];

    normalSet.add(normalValue);
    pivotSet.add(pivotValue);

    normalSortState.update(normalValue, pivotValue);
    pivotSortState.update(pivotValue, normalValue);
  }

  const normalValues = Array.from(normalSet);
  const pivotValues = Array.from(pivotSet);

  normalSortState.sort(normalValues);
  pivotSortState.sort(pivotValues);

  return { normalValues, pivotValues };
}

// This should work for both strings and numbers
const DEFAULT_COMPARE = (a, b) => (a < b ? -1 : a > b ? 1 : 0);

class SortState {
  constructor(compare = DEFAULT_COMPARE) {
    this.compare = compare;

    this.asc = true;
    this.desc = true;
    this.lastValue = undefined;

    this.groupAsc = true;
    this.groupDesc = true;
    this.lastGroupKey = undefined;
    this.isGrouped = false;
  }

  update(value, groupKey) {
    // skip the first value since there's nothing to compare it to
    if (this.lastValue !== undefined) {
      // compare the current value with the previous value
      const result = this.compare(value, this.lastValue);
      // update global sort state
      this.asc = this.asc && result >= 0;
      this.desc = this.desc && result <= 0;
      if (
        // if current and last values are different
        result !== 0 &&
        // and current and last group are same
        this.lastGroupKey !== undefined &&
        this.lastGroupKey === groupKey
      ) {
        // update grouped sort state
        this.groupAsc = this.groupAsc && result >= 0;
        this.groupDesc = this.groupDesc && result <= 0;
        this.isGrouped = true;
      }
    }
    // update last value and group key
    this.lastValue = value;
    this.lastGroupKey = groupKey;
  }

  sort(array) {
    if (this.isGrouped) {
      if (this.groupAsc && this.groupDesc) {
        console.warn("This shouldn't happen");
      } else if (this.groupAsc && !this.asc) {
        array.sort(this.compare);
      } else if (this.groupDesc && !this.desc) {
        array.sort((a, b) => this.compare(b, a));
      }
    }
  }
}

const getTitleForColumn = (column, settings) => {
  const { column: _column, column_title: columnTitle } =
    settings.column(column);
  return columnTitle || formatColumn(_column);
};

export function getTitles(data, settings, columnIndex) {
  const column = data.cols.filter(col => !isPivotGroupColumn(col))[columnIndex];
  return getTitleForColumn(column, settings);
}

function getArrayKeyByLength(length) {
  return JSON.stringify(Array.from({ length: length + 1 }, (_, i) => i));
}

function makeCollapsedChildren(collapsedSubtotalsList, target) {
  let initialIsCollapsed = false;
  if (Array.isArray(collapsedSubtotalsList) && Array.isArray(target)) {
    collapsedSubtotalsList.forEach(subtotalsListItem => {
      const subtotalsListItemArray = JSON.parse(subtotalsListItem);
      target.length - subtotalsListItemArray.length === 1 &&
      subtotalsListItemArray.every(item => target.includes(item))
        ? (initialIsCollapsed = true)
        : null;
    });
  }
  return initialIsCollapsed;
}
