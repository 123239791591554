import { useState } from "react";
import _ from "underscore";

import { ChartSettingIntegerNumeric } from "./ChartSettingsInputIntegerNumeric.styled";

const ALLOWED_CHARS = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

interface ChartSettingInputIntegerNumericProps {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
  onChangeSettings: () => void;
}

export const ChartSettingInputIntegerNumeric = ({
  onChange,
  value,
  ...props
}: ChartSettingInputIntegerNumericProps) => {
  const [internalValue, setInternalValue] = useState(value?.toString() ?? "");

  return (
    <ChartSettingIntegerNumeric
      type="number"
      {..._.omit(props, "onChangeSettings")}
      min={0}
      max={1500}
      value={Number(internalValue)}
      onChange={({ target: { value } }) => {
        if (value !== "") {
          const everyCharValid = value
            .split("")
            .every(char => ALLOWED_CHARS.includes(char));

          if (everyCharValid) {
            setInternalValue(value);
          }
        }
      }}
      onBlur={({ target: { value } }) => {
        const inputNumber = value !== "" ? Number(value) : Number.NaN;
        if (isNaN(inputNumber)) {
          onChange(undefined);
        } else {
          onChange(inputNumber);
        }
      }}
    />
  );
};
