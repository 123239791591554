import { push } from "react-router-redux";
import { t } from "ttag";

import { getEditSegementedAccessUrl } from "metabase/plugins/index";

export const DATA_PERMISSION_OPTIONS = {
  all: {
    label: t`Unrestricted`,
    value: "all",
    icon: "check",
    iconColor: "success",
  },
  restricted: {
    label: t`Restricted`,
    value: "restricted",
    icon: "permissions_limited",
    iconColor: "warning",
  },
  controlled: {
    label: t`Granular`,
    value: "controlled",
    icon: "permissions_limited",
    iconColor: "warning",
  },
  noSelfService: {
    label: t`No self-service`,
    value: "none",
    icon: "eye",
    iconColor: "accent5",
  },
  none: {
    label: t`No`,
    value: "none",
    icon: "close",
    iconColor: "danger",
  },
  write: {
    label: t`Yes`,
    value: "write",
    icon: "check",
    iconColor: "success",
  },
};

export const GLARUSBI_ADMIN_PERMISSIONS_TABLE_FIELDS_ACTIONS = {
  restricted: [
    {
      label: t`Edit restricted access`,
      iconColor: "brand",
      icon: "pencil",
      actionCreator: (entityId, groupId, view) =>
        push(getEditSegementedAccessUrl(entityId, groupId, view)),
    },
  ],
};
