import { t } from "ttag";

import { Tooltip, Icon } from "metabase/ui";

import { FilterButton } from "./DashboardFilterButton.styled";

interface DashboardFilterButtonProps {
  filtersNumber: number;
  isCollapsed: boolean;
  onClick: () => void;
}

export function DashboardFilterButton({
  filtersNumber,
  isCollapsed,
  onClick,
}: DashboardFilterButtonProps) {
  const label = isCollapsed ? t`Hide filters` : t`Show filters`;

  if (filtersNumber === 0) {
    return null;
  }

  return (
    <Tooltip label={label}>
      <FilterButton
        leftIcon={<Icon name="filter" />}
        radius="xl"
        isCollapsed={isCollapsed}
        aria-label={label}
        data-testid="filters-visibility-control"
        onClick={onClick}
      >
        {filtersNumber}
      </FilterButton>
    </Tooltip>
  );
}
