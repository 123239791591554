var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var l7,m7,n7,nta,ota,pta,q7,r7,t7,u7,qta,rta,sta,w7,x7,y7,tta,uta;l7=function(a){var b=new $CLJS.h(null,3,[$CLJS.vD,$CLJS.p.h($CLJS.FD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ni,$CLJS.zz($CLJS.ni,$CLJS.tA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,b,$CLJS.zz($CLJS.nD,$CLJS.U)(a)],null)};m7=function(a){return $CLJS.R.j(a,$CLJS.Vs,$CLJS.C4)};n7=function(a,b){return $CLJS.E.g($CLJS.iD.h(a),$CLJS.Bl(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zD,$CLJS.hD,$CLJS.yD],null)))};
nta=function(a){return $CLJS.O0(a,new $CLJS.h(null,1,[$CLJS.zD,$CLJS.mh],null))};
ota=function(a,b){var c=$CLJS.Me(function(e){return $CLJS.qG(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IY,$CLJS.U],null));if($CLJS.n(c))return c;c=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",c))){var d=$CLJS.eD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.ek.g($CLJS.IY,b)]))]));d instanceof Error?$CLJS.Kz("metabase.lib.field",c,$CLJS.xv(),d):$CLJS.Kz("metabase.lib.field",
c,$CLJS.xv.l($CLJS.H([d])),null)}return null};
pta=function(a,b,c){if($CLJS.n(o7))return null;var d=o7;o7=!0;try{var e=$CLJS.H1(a,b),f=$CLJS.n(e)?$CLJS.CV(a,e):$CLJS.CV(a,b),k=function(){var m=$CLJS.p7.h(f);if($CLJS.n(m))return m;m=$CLJS.Bz(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lU,$CLJS.zV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.LU.h(f);if($CLJS.n(u))return u;u=$CLJS.cO.h(f);if($CLJS.n(u))return u;u=$CLJS.XO.h(f);return $CLJS.n(u)?u:$CLJS.PD.h(f)}())?$CLJS.W_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",
m))){var t=$CLJS.eD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Kz("metabase.lib.field",m,$CLJS.xv(),t):$CLJS.Kz("metabase.lib.field",m,$CLJS.xv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?ota(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.i3($CLJS.Vj.l(l,$CLJS.Gi,$CLJS.H([$CLJS.XJ,$CLJS.LV,$CLJS.GV])),null),$CLJS.U,function(){var m=$CLJS.IY.h(l);return $CLJS.n(m)?m:$CLJS.U.h(l)}()),
$CLJS.KJ,$CLJS.tY):l:null}finally{o7=d}};
q7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.rO);var f=$CLJS.I(c,2,null);c=$CLJS.uk.l($CLJS.H([function(){var k=$CLJS.tA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.tA,k],null):null}(),function(){var k=$CLJS.zz($CLJS.ni,$CLJS.tA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.ni,k],null):null}(),function(){var k=$CLJS.rQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.LV,k],null):null}(),function(){var k=$CLJS.nF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.GV,k],null):null}(),$CLJS.Ad(f)?function(){var k=$CLJS.N1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.IJ,$CLJS.U,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.IJ,$CLJS.U,$CLJS.p.h(f)],null):function(){var k=pta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.IJ,$CLJS.U,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.i3(c,d):c};
r7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.GV);return $CLJS.n($CLJS.n(b)?$CLJS.Cd($CLJS.nG,b):b)?$CLJS.Yi:$CLJS.zz($CLJS.ni,$CLJS.tA)(a)};
$CLJS.s7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.vD),l=$CLJS.J.g(f,$CLJS.tA),m=$CLJS.J.g(f,$CLJS.rQ),t=$CLJS.J.g(f,$CLJS.ni);e=$CLJS.J.g(f,$CLJS.rO);var u=$CLJS.J.g(f,$CLJS.CM),v=$CLJS.J.g(f,$CLJS.nF),x=$CLJS.uk.l;k=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.IJ,$CLJS.B0,k],null);f=$CLJS.jD.h(f);a=$CLJS.n(f)?f:$CLJS.j_.j(a,b,d);c=x.call($CLJS.uk,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.jD,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.ni,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.tA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.GV,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.LV,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.WJ,u):m;return $CLJS.n(e)?$CLJS.i3(u,e):u};t7=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.di.h(d),c)},a))?$CLJS.ek.g(function(d){var e=$CLJS.Cd(d,b)?$CLJS.Vj.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.di.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
u7=function(a){var b=$CLJS.n($CLJS.o1.h(a))?null:function(){var e=$CLJS.KJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.MY,null,$CLJS.TY,null,$CLJS.tY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.uk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.vD,$CLJS.p.h($CLJS.FD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ni,r7(a)],null),function(){var e=function(){var f=$CLJS.Ra(b);return f?(f=$CLJS.Ra($CLJS.WJ.h(a)))?(f=$CLJS.bk.g($CLJS.EY,$CLJS.KJ.h(a)))?$CLJS.XY.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.rO,e],null):null}(),function(){var e=$CLJS.h1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.rO,e],null):null}(),function(){var e=$CLJS.GV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.nF,e],null):null}(),function(){var e=$CLJS.KV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.KV,e],null):null}(),function(){var e=$CLJS.LV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.rQ,e],null):null}(),function(){var e=$CLJS.WJ.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.CM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.zz($CLJS.IY,$CLJS.U):$CLJS.zz($CLJS.Gi,$CLJS.U))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,c,d],null)};qta=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.KJ.h(c),$CLJS.CY)},$CLJS.W_.v(a,b,$CLJS.CV(a,b),new $CLJS.h(null,3,[$CLJS.D_,!1,$CLJS.J_,!0,$CLJS.z_,!1],null)))};rta=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);sta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.v7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);w7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);x7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.p7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);y7=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
tta=new $CLJS.M(null,"earliest","earliest",-1928154382);uta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.P0.m(null,$CLJS.hF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mh.h(b),$CLJS.O0(c,new $CLJS.h(null,2,[$CLJS.nF,$CLJS.mh,$CLJS.rQ,nta],null)),a],null)});
var o7=!1,z7=function z7(a,b){var d=$CLJS.Y0(a,x7.h(b));a=$CLJS.n(x7.h(d))?z7.g?z7.g(a,d):z7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.U),f=$CLJS.J.g(a,$CLJS.jD);return $CLJS.fk.j($CLJS.R.j($CLJS.fk.j($CLJS.R.j(b,rta,$CLJS.U.h(b)),$CLJS.U,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),y7,$CLJS.jD.h(b)),$CLJS.jD,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.O_.m(null,$CLJS.IJ,function(a,b,c){return r7(c)});
$CLJS.O_.m(null,$CLJS.hF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.nF);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.GV,d):c;return $CLJS.P_.j(a,b,c)});$CLJS.Q_.m(null,$CLJS.IJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.U);return $CLJS.R.j(a,$CLJS.U,b)});$CLJS.Q_.m(null,$CLJS.hF,function(a,b,c){var d=q7(a,b,c);b=$CLJS.s7(a,b,d,c);return $CLJS.n(x7.h(b))?z7(a,b):b});
$CLJS.K_.m(null,$CLJS.IJ,function(a,b,c,d){function e(T){var da=$CLJS.i5(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}function f(T){var da=$CLJS.cA($CLJS.Gz($CLJS.Xg(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",T,da):$CLJS.ha.call(null,"%s: %s",T,da)}var k=$CLJS.O(c),l=$CLJS.J.g(k,$CLJS.LV),m=$CLJS.J.g(k,$CLJS.WJ),t=$CLJS.J.g(k,$CLJS.XJ),u=$CLJS.J.g(k,$CLJS.di),v=$CLJS.J.g(k,$CLJS.XY),x=$CLJS.J.g(k,y7),A=$CLJS.J.g(k,$CLJS.U),C=$CLJS.J.g(k,$CLJS.I5),G=$CLJS.J.g(k,
$CLJS.jD),K=$CLJS.J.g(k,x7),S=$CLJS.f1.g($CLJS.e1,A);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(K)?null==G||$CLJS.E.g(G,S):K)){a:{var T=$CLJS.O(k);var da=$CLJS.J.g(T,$CLJS.jD);T=$CLJS.J.g(T,x7);for(da=new $CLJS.Vd(null,da,null,1,null);;)if($CLJS.n(T)){T=$CLJS.Y0(a,T);var oa=$CLJS.O(T);T=$CLJS.J.g(oa,$CLJS.jD);oa=$CLJS.J.g(oa,x7);da=$CLJS.Yd.g(da,T);T=oa}else break a}da=$CLJS.Le($CLJS.Sa,da)?$CLJS.hs(": ",da):null}else da=null;return $CLJS.n(da)?da:$CLJS.n(G)?G:"string"===typeof A?S:$CLJS.p.h(A)}();
var X=$CLJS.E.g(d,$CLJS.k_)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var T=$CLJS.Y0(a,m);$CLJS.n(T)?T=$CLJS.e3($CLJS.jD.h($CLJS.m_.j(a,b,T))):(T=$CLJS.g1(a,t),T=$CLJS.j_.v(a,b,T,d))}else T=null;return $CLJS.n(T)?T:$CLJS.n(v)?v:$CLJS.h1(k)}():null;c=$CLJS.n(X)?[$CLJS.p.h(X)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var T=$CLJS.bk.g(d,$CLJS.k_);return T?C:T}())?c:$CLJS.n($CLJS.n(u)?$CLJS.bk.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?$CLJS.bk.g(c,e(S)):l)?e(c):c});
$CLJS.K_.m(null,$CLJS.hF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.rQ);var k=$CLJS.J.g(f,$CLJS.rO),l=$CLJS.J.g(f,$CLJS.nF);f=$CLJS.J.g(f,$CLJS.CM);$CLJS.I(c,2,null);c=q7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.XY,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.di,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.LV,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.WJ,f):e;return $CLJS.n(e)?$CLJS.j_.v(a,b,e,d):$CLJS.GD("[Unknown Field]")});
$CLJS.L_.m(null,$CLJS.IJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.U)});$CLJS.L_.m(null,$CLJS.hF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=q7(a,b,c);return $CLJS.n(c)?$CLJS.M_.j(a,b,c):"unknown_field"});
$CLJS.R_.m(null,$CLJS.IJ,function(a,b,c){return $CLJS.uk.l($CLJS.H([function(){var d=$CLJS.jH($CLJS.R_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.j_.j(a,b,c),$CLJS.l_,$CLJS.j_.v(a,b,c,$CLJS.k_)],null),$CLJS.E.g($CLJS.KJ.h(c),$CLJS.MY)?function(){var d=$CLJS.WY.h(c);return $CLJS.n(d)?(d=$CLJS.d_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.Hj,new $CLJS.h(null,2,[$CLJS.U,$CLJS.U.h(d),$CLJS.jD,$CLJS.U.h(d)],null)],null):null):null}():null]))});
$CLJS.s0.m(null,$CLJS.hF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.nF.h(b)});$CLJS.s0.m(null,$CLJS.IJ,function(a){return $CLJS.GV.h(a)});
$CLJS.r0.m(null,$CLJS.hF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Cd($CLJS.nG,b),e=$CLJS.pB($CLJS.KV,$CLJS.ni,$CLJS.tA)(c);c=$CLJS.R.l(c,$CLJS.nF,b,$CLJS.H([$CLJS.ni,d?$CLJS.Yi:e,$CLJS.KV,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,c,a],null)}b=$CLJS.KV.h(c);c=$CLJS.n(b)?$CLJS.Vj.g($CLJS.R.j(c,$CLJS.ni,b),$CLJS.KV):c;c=$CLJS.Vj.g(c,$CLJS.nF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,c,a],null)});
$CLJS.r0.m(null,$CLJS.IJ,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.GV,b,$CLJS.H([$CLJS.KV,$CLJS.pB($CLJS.KV,$CLJS.ni,$CLJS.tA)(a)])):$CLJS.Vj.l(a,$CLJS.GV,$CLJS.H([$CLJS.KV]))});$CLJS.u0.m(null,$CLJS.hF,function(a,b,c){return $CLJS.v0.j(a,b,q7(a,b,c))});
$CLJS.u0.m(null,$CLJS.IJ,function(a,b,c){if($CLJS.bk.g($CLJS.KJ.h(c),$CLJS.CY)){a=$CLJS.zz($CLJS.ni,$CLJS.tA)(c);b=null==c?null:$CLJS.QM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Dk.h($CLJS.Ri.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,tta),l=$CLJS.J.g(f,uta),m=$CLJS.kpa.l($CLJS.H([$CLJS.p0.h(k),$CLJS.p0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Aq.g?$CLJS.Aq.g(1,m):$CLJS.Aq.call(null,1,m))?$CLJS.Ck:$CLJS.n($CLJS.Aq.g?$CLJS.Aq.g(31,m):$CLJS.Aq.call(null,31,m))?$CLJS.Jk:$CLJS.n($CLJS.Aq.g?
$CLJS.Aq.g(365,m):$CLJS.Aq.call(null,365,m))?$CLJS.Ti:$CLJS.Zi}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.Dz(a,$CLJS.Dk)?$CLJS.vpa:$CLJS.Dz(a,$CLJS.Ak)?$CLJS.upa:$CLJS.Dz(a,$CLJS.Kk)?$CLJS.tpa:$CLJS.xf;d=$CLJS.n(d)?t7(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.GV.h(c))?t7(d,$CLJS.r_,$CLJS.GV.h(c)):d}return $CLJS.xf});
$CLJS.f5.m(null,$CLJS.hF,function(a){var b=null==a?null:$CLJS.CD(a);b=null==b?null:$CLJS.rQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.k5,$CLJS.H([$CLJS.j5,function(c,d){return q7(c,d,a)}]))});$CLJS.f5.m(null,$CLJS.IJ,function(a){var b=null==a?null:$CLJS.LV.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.k5,$CLJS.H([$CLJS.j5,$CLJS.Pe(a)]))});$CLJS.l5.m(null,$CLJS.hF,function(a,b){return $CLJS.DD(a,$CLJS.BD,$CLJS.H([$CLJS.rQ,b]))});
$CLJS.l5.m(null,$CLJS.IJ,function(a,b){return $CLJS.BD(a,$CLJS.LV,b)});$CLJS.m5.m(null,$CLJS.hF,function(a,b,c){return $CLJS.n5.j(a,b,q7(a,b,c))});
$CLJS.m5.m(null,$CLJS.IJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.ni);var d=$CLJS.J.g(b,$CLJS.QM),e=$CLJS.J.g(b,$CLJS.rj);if($CLJS.bk.g($CLJS.KJ.h(b),$CLJS.CY)){var f=function(){var m=null==a?null:$CLJS.X0(a);m=null==m?null:$CLJS.dz.h(m);return null==m?null:$CLJS.Cd(m,$CLJS.rQ)}(),k=$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,$CLJS.nj],null)),l=$CLJS.g5(b);return function u(t){return new $CLJS.ie(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.vd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),
C=$CLJS.le(A);return function(){for(var K=0;;)if(K<A){var S=$CLJS.hd(x,K),X=C,T=S;S=n7(S,l)?$CLJS.R.j(T,$CLJS.r_,!0):T;X.add(S);K+=1}else return!0}()?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}var G=$CLJS.z(v);return $CLJS.ae(function(){var K=G;return n7(G,l)?$CLJS.R.j(K,$CLJS.r_,!0):K}(),u($CLJS.Hc(v)))}return null}},null,null)}($CLJS.Ra(function(){if($CLJS.n(f)){var t=$CLJS.ri.h(k);return $CLJS.n(t)?$CLJS.Aj.h(k):t}return f}())?null:$CLJS.Dz(e,$CLJS.$i)?$CLJS.ek.g(m7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.h5(),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("Bin every 0.1 degrees"),$CLJS.iD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.yD,$CLJS.yD,.1],null)],null),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("Bin every 1 degree"),$CLJS.iD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.yD,$CLJS.yD,1],null)],null),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("Bin every 10 degrees"),$CLJS.iD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.yD,$CLJS.yD,10],null)],null),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("Bin every 20 degrees"),$CLJS.iD,new $CLJS.h(null,
2,[$CLJS.zD,$CLJS.yD,$CLJS.yD,20],null)],null)],null)):$CLJS.Dz(c,$CLJS.nj)&&!$CLJS.Dz(e,$CLJS.$h)?$CLJS.ek.g(m7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.h5(),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("10 bins"),$CLJS.iD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.hD,$CLJS.hD,10],null)],null),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("50 bins"),$CLJS.iD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.hD,$CLJS.hD,50],null)],null),new $CLJS.h(null,2,[$CLJS.jD,$CLJS.GD("100 bins"),$CLJS.iD,new $CLJS.h(null,2,[$CLJS.zD,$CLJS.hD,$CLJS.hD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.lV.m(null,$CLJS.hF,function(a){return a});
$CLJS.lV.m(null,$CLJS.IJ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.KJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.vD,$CLJS.p.h($CLJS.FD()),$CLJS.ni,$CLJS.zz($CLJS.ni,$CLJS.tA)(a),$CLJS.xK,$CLJS.U.h(a)],null),a=$CLJS.B0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,b,a],null);case "source/expressions":return l7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.nD.h(a))?l7(a):u7(a);default:return u7(a)}});
$CLJS.vta=function(){function a(e,f,k){k=$CLJS.Be($CLJS.ek.g($CLJS.nV,k));var l=qta(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.tl(function(t){return $CLJS.T1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.dk.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.nV),m):null;return $CLJS.NV.l(e,f,$CLJS.BD,$CLJS.H([$CLJS.PD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.A7=function(){function a(d,e){return $CLJS.PD.h($CLJS.CV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.wta=function(){function a(d,e){var f=$CLJS.W_.v(d,e,$CLJS.CV(d,e),new $CLJS.h(null,3,[$CLJS.D_,!1,$CLJS.J_,!1,$CLJS.z_,!1],null)),k=$CLJS.A7.g(d,e);return $CLJS.od(k)?$CLJS.ek.g(function(l){return $CLJS.R.j(l,$CLJS.KY,!0)},f):$CLJS.g3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y(w7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.cj,$CLJS.wY,$CLJS.cz],null));
$CLJS.Y(sta,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.v7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,w7],null)],null)],null));