var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var SV,UV,WV,XV,Xma,ZV,$V,RV,aW,dW,Yma,Zma,$ma,ana,fW,gW,iW,jW,kW,lW,mW,nW,cna,dna,pW,ena,qW,fna,sW,gna,hna,ina,jna,kna,OV;$CLJS.PV=function(a,b){if("string"===typeof b)return OV(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.QV=function(a,b,c){var d=$CLJS.Cl(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Cb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
SV=function(a){var b=RV;return $CLJS.Iz(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};$CLJS.TV=function(a,b){return $CLJS.Iz(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};UV=function(a){if($CLJS.id(a))return a;throw $CLJS.Uh("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.pi,a],null));};
$CLJS.VV=function(a,b,c){return $CLJS.sd(c)?$CLJS.Wf.g(c,function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,X=G;G=$CLJS.Yd.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[X,G],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var A=$CLJS.Yd.g(b,v),C=x;return a.g?a.g(A,C):a.call(null,A,C)}()],null),f($CLJS.Hc(k)))}return null}},null,null)}(c)}()):$CLJS.rd(c)?$CLJS.ek.g($CLJS.Qe(a,$CLJS.z(c)instanceof $CLJS.M?$CLJS.Yd.g(b,$CLJS.z(c)):b),c):c};WV=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.de(a):b;return $CLJS.n(b)?[$CLJS.de(a),"/",$CLJS.Xg(a)].join(""):$CLJS.Xg(a)};
XV=function(a,b){var c=$CLJS.rd(b)&&!$CLJS.uf(b)&&$CLJS.z(b)instanceof $CLJS.M;return c?$CLJS.pd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c};
Xma=function(a,b){return $CLJS.df(function(c){var d=$CLJS.ff;a:try{if($CLJS.ud(c)&&1<=$CLJS.D(c))try{var e=$CLJS.jk.j(c,0,1);if($CLJS.ud(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Qe($CLJS.E,a)(f))){var k=$CLJS.jk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Z;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw t;}else throw $CLJS.Z;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Z){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.z($CLJS.y(d($CLJS.Sa,l)))},$CLJS.H([b]))};
ZV=function(a,b){for(;;)switch(b=$CLJS.vk.h($CLJS.ff($CLJS.Sa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.z(b),YV.h?YV.h(a):YV.call(null,a);default:if($CLJS.n($CLJS.Me($CLJS.Qe(XV,a),b))){var c=a;b=Xma(a,b);a=c}else{c=$CLJS.cf.g(YV,b);if($CLJS.E.g(c,b))return $CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};$V=function(a){var b=$CLJS.rd(a);return b?(b=!$CLJS.uf(a))?$CLJS.zz($CLJS.be,$CLJS.Wa)($CLJS.z(a)):b:b};
RV=function(a){return $CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a))?$CLJS.mh.h($CLJS.Gz(WV(a).toLowerCase(),/_/,"-")):a};aW=function(a,b){var c=$V(b);return $CLJS.n(c)?(b=RV($CLJS.z(b)),$CLJS.pd(a)?(a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};
dW=function(a){a=bW.g?bW.g(a,cW):bW.call(null,a,cW);var b=$CLJS.n($CLJS.tA.h(a))?$CLJS.fk.j(a,$CLJS.tA,$CLJS.mh):a;b=$CLJS.n($CLJS.nF.h(a))?$CLJS.fk.j(b,$CLJS.nF,$CLJS.mh):b;return $CLJS.n($CLJS.rQ.h(a))?$CLJS.fk.j(b,$CLJS.rQ,function(c){return $CLJS.n($CLJS.zD.h(c))?$CLJS.fk.j(c,$CLJS.zD,$CLJS.mh):c}):b};
Yma=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.Oh,$CLJS.Pd,$CLJS.Ri,RV,$CLJS.OM,RV],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ae([a,b]);b=bW.g?bW.g(b,cW):bW.call(null,b,cW);return d.call(c,b,a)})};
Zma=function(a){a=$CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=RV(c);var d=Yma(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.Ri.h(a),$CLJS.AE)&&$CLJS.Ra($CLJS.OM.h(a))?$CLJS.R.j(a,$CLJS.OM,$CLJS.AF):a};$ma=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=WV(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(Zma(b),$CLJS.U,c)],null)}),a)};
ana=function(a){a=bW.h?bW.h(a):bW.call(null,a);return eW.h?eW.h(a):eW.call(null,a)};fW=function(a){return $CLJS.QV($CLJS.QV($CLJS.cb(function(b,c){return $CLJS.QV(b,c,$CLJS.mh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.bC,$CLJS.vga,$CLJS.BN,bna,$CLJS.Yy,$CLJS.di],null)),$CLJS.eB,ana),$CLJS.QM,$CLJS.sU)};gW=function(a){return $CLJS.Ad(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,a,null],null):a};iW=function(a){return hW.h(gW(a))};
jW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return YV($CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.g(hW,b)))};kW=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,iW(b)],null),$CLJS.cf.g(hW,c))};lW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,iW(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
mW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,iW(a)],null)};nW=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,hW.h(a)],null)};cna=function(a){return $CLJS.yf($CLJS.zl(eW,oW(a)))};dna=function(a){for(;;)if($CLJS.n($V(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Be($CLJS.ek.g(gW,a))};
pW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.AE);return $CLJS.n(b)?$CLJS.fk.j(a,$CLJS.AE,hW):a};
ena=function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,pW(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,
2,5,$CLJS.Q,[l,pW(f)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};qW=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.DP);return $CLJS.n(b)?$CLJS.fk.j(a,$CLJS.DP,ena):a};fna=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.gP);b=$CLJS.Ra(a)?rW.h?rW.h(b):rW.call(null,b):b;return $CLJS.n(a)?qW(b):b};sW=function(a){return $CLJS.pd(a)?$CLJS.y(a):null!=a};
gna=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.DE);var c=$CLJS.J.g(b,$CLJS.sM),d=$CLJS.J.g(b,$CLJS.PN),e=$CLJS.J.g(b,$CLJS.gP);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.PN);var l=$CLJS.UK($CLJS.Vj.g(f,$CLJS.PN),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DE,$CLJS.PN],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.fk.j(l,$CLJS.DE,rW):l,t=$CLJS.n(c)?$CLJS.fk.j(m,$CLJS.sM,$CLJS.Qe($CLJS.ek,eW)):m,u=$CLJS.n(e)?$CLJS.fk.j(t,$CLJS.gP,qW):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.gP);return $CLJS.n(x)?$CLJS.R.j(eW($CLJS.Vj.g(v,
$CLJS.gP)),$CLJS.gP,x):eW(v)}catch(A){if(A instanceof Error)throw m=A,$CLJS.Th($CLJS.eD("Error canonicalizing query: {0}",$CLJS.H([$CLJS.qU(m)])),new $CLJS.h(null,1,[$CLJS.DE,a],null),m);throw A;}};
hna=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.DE),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.WE);c=$CLJS.J.g(c,$CLJS.PD);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.Wf.j($CLJS.Sg,$CLJS.qB,$CLJS.y($CLJS.ff($CLJS.Sa,function k(e,f){try{if($CLJS.ud(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.ce(l,$CLJS.hF)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,m,$CLJS.Vj.g(t,$CLJS.nF)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.xU(k,e,f);throw l;}throw u;}}($CLJS.xf,b)))),$CLJS.tW.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DE,$CLJS.PD],null),$CLJS.ck.g($CLJS.yf,$CLJS.Qe($CLJS.dk,b)))):a};
ina=function(a){try{return hna(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.Th($CLJS.GD("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.DE,a],null),b);}throw c;}};
jna=function(a,b){var c=$CLJS.Wf.g($CLJS.fd(a),function(){return function f(e){return new $CLJS.ie(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);return function(){for(var A=0;;)if(A<m){var C=$CLJS.hd(l,A),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.Yd.g(b,G);C=uW.g?uW.g(C,K):uW.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));A+=1}else return!0}()?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}var u=$CLJS.z(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var A=x,C=$CLJS.Yd.g(b,v);return uW.g?uW.g(A,C):uW.call(null,A,C)}();if(null!=u)return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Hc(k)));k=$CLJS.Hc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};kna=function(a,b){a=$CLJS.ek.g(function(c){var d=$CLJS.Yd.g(b,vW);return uW.g?uW.g(c,d):uW.call(null,c,d)},a);return $CLJS.n($CLJS.Me($CLJS.Sa,a))?a:null};
OV=function OV(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.yf(d);return $CLJS.ae(f,new $CLJS.ie(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),OV.g?OV.g(a,k):OV.call(null,a,k)):null},null,null))};
$CLJS.tW=function tW(a){switch(arguments.length){case 3:return tW.j(arguments[0],arguments[1],arguments[2]);case 4:return tW.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return tW.M(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return tW.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return tW.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.tW.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.z(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.tW.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.tW.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.z(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.tW.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.tW.M=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.z(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.tW.M($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.tW.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.z(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.tW.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.tW.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.z(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Ne.l($CLJS.tW,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Ne.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.tW.B=function(a){var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);var e=$CLJS.B(d);d=$CLJS.z(e);var f=$CLJS.B(e);e=$CLJS.z(f);var k=$CLJS.B(f);f=$CLJS.z(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.tW.A=6;
var YV=function YV(a){for(;;){if($CLJS.sd(a))return $CLJS.gs(a,YV);if($CLJS.yd(a))a=$CLJS.yf(a);else if($CLJS.ud(a)){if($CLJS.Ra($CLJS.Me($CLJS.Sa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.z(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.S:null;switch(v){case "not":if($CLJS.il(m)){var x=$CLJS.z(m),A=x instanceof $CLJS.M?x.S:null;switch(A){case "not":a=$CLJS.dd(m);continue;case "and":return ZV($CLJS.dr,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.fr,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));case "or":return ZV($CLJS.kr,$CLJS.cf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,C],null)}}(a,x,A,u,v,c,d,e,f,k,l,m,t),$CLJS.Hc(m)));default:return a}}else return a;case "and":return ZV($CLJS.kr,t);case "or":return ZV($CLJS.dr,t);default:return $CLJS.ek.g(YV,a)}}else return a}},lna=new $CLJS.M(null,"value_field","value_field",-980977878),wW=new $CLJS.M(null,"ascending","ascending",-988350486),
xW=new $CLJS.M(null,"named","named",-422393479),yW=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),zW=new $CLJS.M(null,"descending","descending",-24766135),AW=new $CLJS.M(null,"datetime-field","datetime-field",21731696),mna=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),BW=new $CLJS.M(null,"field-literal","field-literal",-1295883554),CW=new $CLJS.M(null,"viz-settings","viz-settings",256055379),nna=new $CLJS.M(null,"rows","rows",850049680),ona=
new $CLJS.M(null,"special-fn","special-fn",1290649344),vW=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),cW=new $CLJS.M(null,"ignore-path","ignore-path",944069061),pna=new $CLJS.M(null,"label_field","label_field",-1573182765),qna=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),rna=new $CLJS.M(null,"joined-field","joined-field",-2048778268),bna=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var DW,sna=$CLJS.Se($CLJS.N),tna=$CLJS.Se($CLJS.N),una=$CLJS.Se($CLJS.N),vna=$CLJS.Se($CLJS.N),wna=$CLJS.J.j($CLJS.N,$CLJS.Oi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));DW=new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.ck.g(RV,$CLJS.z),wna,sna,tna,una,vna);DW.m(null,$CLJS.XE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,b],null);return null!=a?$CLJS.Yd.g(b,a):b});
DW.m(null,$CLJS.qA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qA,b instanceof $CLJS.M?WV(b):b],null);a=$CLJS.Be($CLJS.TV(new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.tA,null,$CLJS.nF,null,$CLJS.rQ,null],null),null),dW(a)));return $CLJS.n(a)?$CLJS.Yd.g(b,a):b});
DW.m(null,$CLJS.zU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.Yd.g(DW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zU,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zU,bW.g?bW.g(b,cW):bW.call(null,b,cW),RV(c)],null)});DW.m(null,$CLJS.hF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,b,dW(a)],null)});
DW.m(null,BW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[BW,b instanceof $CLJS.M?WV(b):b,$CLJS.mh.h(a)],null)});DW.m(null,AW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[AW,bW.g?bW.g(b,cW):bW.call(null,b,cW),$CLJS.Xr,RV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[AW,bW.g?bW.g(b,cW):bW.call(null,b,cW),RV(c)],null)});
DW.m(null,$CLJS.cB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.Yd.g(DW.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cB,b,c,d],null)),bW.g?bW.g(a,cW):bW.call(null,a,cW)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cB,bW.g?bW.g(b,cW):bW.call(null,b,cW),$CLJS.Ad(c)?c:RV(c),RV(d)],null)});
DW.m(null,$CLJS.ZA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZA,b,RV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZA,$CLJS.Qu],null)});DW.m(null,$CLJS.YA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YA,b,RV(a)],null)});
DW.m(null,$CLJS.aB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.aB,bW.g?bW.g(b,cW):bW.call(null,b,cW),c,RV(a)],null)});DW.m(null,$CLJS.dB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dB,bW.g?bW.g(b,cW):bW.call(null,b,cW),c,RV(a)],null)});
DW.m(null,$CLJS.$A,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$A,bW.g?bW.g(b,cW):bW.call(null,b,cW),RV(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,bW.g?bW.g(b,cW):bW.call(null,b,cW)],null)});
DW.m(null,$CLJS.bB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bB,bW.g?bW.g(b,cW):bW.call(null,b,cW),RV(c),RV(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bB,bW.g?bW.g(b,cW):bW.call(null,b,cW),RV(c)],null)});
DW.m(null,$CLJS.XA,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.XA,bW.g?bW.g(b,cW):bW.call(null,b,cW),bW.g?bW.g(c,cW):bW.call(null,c,cW),RV(a)],null)});DW.m(null,$CLJS.dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dj,b,a],null)});
DW.m(null,$CLJS.Oh,function(a){var b=$CLJS.y(a);a=$CLJS.z(b);b=$CLJS.B(b);return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[RV(a)],null),$CLJS.cf.h(function(c){return bW.g?bW.g(c,cW):bW.call(null,c,cW)}),b)});
var EW=function EW(a){if($CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a))){var c=RV(a);var d=new $CLJS.Rg(null,new $CLJS.h(null,18,[$CLJS.ri,null,$CLJS.Jq,null,$CLJS.wF,null,$CLJS.qF,null,$CLJS.Zr,null,$CLJS.iF,null,$CLJS.BF,null,$CLJS.CF,null,$CLJS.ME,null,$CLJS.TE,null,$CLJS.Iq,null,$CLJS.NE,null,$CLJS.uF,null,$CLJS.oF,null,$CLJS.Aj,null,$CLJS.Rw,null,$CLJS.bF,null,$CLJS.tF,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n($V(a))?(a=$CLJS.z(a),EW.h?EW.h(a):EW.call(null,
a)):null},xna=new $CLJS.h(null,8,[$CLJS.Ri,RV,$CLJS.gP,function(a){a=SV(a);return $CLJS.y($CLJS.DP.h(a))?$CLJS.fk.j(a,$CLJS.DP,$ma):a},$CLJS.DE,new $CLJS.h(null,6,[$CLJS.XE,function FW(a){if($CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(a)))return RV(a);if($CLJS.n(aW(xW,a))){a=$CLJS.y(a);$CLJS.z(a);var c=$CLJS.B(a);a=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[xW,FW.h?FW.h(a):FW.call(null,a)],null),c)}return $CLJS.n($CLJS.n($V(a))?EW($CLJS.dd(a)):null)?$CLJS.ek.g(FW,a):bW.g?bW.g(a,
cW):bW.call(null,a,cW)},$CLJS.XO,function(a){return $CLJS.Wf.g($CLJS.N,function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[WV(u),bW.g?bW.g(t,cW):bW.call(null,t,cW)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=
$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[WV(l),bW.g?bW.g(f,cW):bW.call(null,f,cW)],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.LQ,function(a){return $CLJS.yf(function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m);t=$CLJS.n($V(t))?DW.h(t):$CLJS.Zd(DW.h($CLJS.Zd(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}l=$CLJS.z(e);return $CLJS.ae($CLJS.n($V(l))?DW.h(l):$CLJS.Zd(DW.h($CLJS.Zd(l))),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())},$CLJS.FP,function(a){a=SV(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.gP);if($CLJS.n(b))return a=$CLJS.wU(a,new $CLJS.h(null,1,[$CLJS.gP,$CLJS.DE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.gP],null),a=bW.g?bW.g(a,b):bW.call(null,a,b),$CLJS.wU(a,new $CLJS.h(null,1,[$CLJS.DE,$CLJS.gP],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.DE],null);return bW.g?bW.g(a,b):bW.call(null,a,b)},$CLJS.PN,new $CLJS.h(null,1,[vW,fW],null),$CLJS.BM,new $CLJS.h(null,1,[vW,function(a){a=bW.g?bW.g(a,$CLJS.DE):bW.call(null,a,$CLJS.DE);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.zD),d=$CLJS.J.g(b,$CLJS.PD);a=$CLJS.J.g(b,$CLJS.TD);b=$CLJS.n(c)?$CLJS.fk.j(b,$CLJS.zD,RV):b;d=$CLJS.n($CLJS.zz($CLJS.be,$CLJS.Wa)(d))?$CLJS.fk.j(b,$CLJS.PD,RV):b;return $CLJS.n(a)?$CLJS.fk.j(d,$CLJS.TD,WV):d}],null)],null),$CLJS.Gx,
new $CLJS.h(null,1,[$CLJS.mN,$CLJS.Pd],null),$CLJS.sM,new $CLJS.h(null,1,[vW,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ri),d=$CLJS.J.g(b,$CLJS.mI),e=$CLJS.J.g(b,$CLJS.Gi);a=$CLJS.J.g(b,yW);b=$CLJS.n(e)?$CLJS.fk.j(b,$CLJS.Gi,WV):b;c=$CLJS.n(c)?$CLJS.fk.j(b,$CLJS.Ri,RV):b;d=$CLJS.n(d)?$CLJS.fk.j(c,$CLJS.mI,function(f){return bW.g?bW.g(f,cW):bW.call(null,f,cW)}):c;d=$CLJS.n(a)?$CLJS.tW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[yW,pna],null),function(f){return bW.g?bW.g(f,cW):bW.call(null,f,cW)}):d;return $CLJS.n(a)?
$CLJS.tW.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[yW,lna],null),function(f){return bW.g?bW.g(f,cW):bW.call(null,f,cW)}):d}],null),$CLJS.nM,function(a){return null==a?null:RV(a)},$CLJS.PN,new $CLJS.h(null,1,[vW,fW],null),CW,RV],null),bW=function bW(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return bW.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
bW.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.yf(b);b=$CLJS.y(c)?$CLJS.Bz(xna,c):null;try{return $CLJS.id(b)?b.h?b.h(a):b.call(null,a):$CLJS.td(a)?a:$CLJS.sd(a)?$CLJS.Wf.g($CLJS.N,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.vd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.hd(m,v),A=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);A=RV(A);A=new $CLJS.P(null,
2,5,$CLJS.Q,[A,bW.l(x,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}m=$CLJS.z(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=RV(u);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[u,bW.l(m,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),u)]))],null),k($CLJS.Hc(l)))}return null}},null,null)}(a)}()):$CLJS.n($V(a))?DW.h(a):$CLJS.rd(a)?$CLJS.ek.g(function(e){return bW.l(e,$CLJS.H([$CLJS.Yd.g($CLJS.yf(c),vW)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.Th($CLJS.eD("Error normalizing form: {0}",$CLJS.H([$CLJS.qU(d)])),new $CLJS.h(null,3,[$CLJS.Ci,a,$CLJS.Nk,c,ona,b],null),d);}throw e;}};bW.A=1;bW.B=function(a){var b=$CLJS.z(a);a=$CLJS.B(a);return this.l(b,a)};
var hW=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Oi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n($V(f))?$CLJS.z(f):null},e,a,b,c,d)}();hW.m(null,$CLJS.Oh,function(a){return a});
hW.m(null,$CLJS.hF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(aW($CLJS.hF,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return hW.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,c,$CLJS.Be($CLJS.uk.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,b,$CLJS.Be(a)],null)});
hW.m(null,$CLJS.XE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.od(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,b,a],null)});hW.m(null,$CLJS.SJ,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n($V(a))?hW.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,a,null],null)});
hW.m(null,BW,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,b,new $CLJS.h(null,1,[$CLJS.tA,a],null)],null)});hW.m(null,qna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=iW(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=iW(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,a,$CLJS.R.j(c,$CLJS.CM,b)],null)});
hW.m(null,rna,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=iW(a);return $CLJS.Ne.v($CLJS.EU,a,$CLJS.R,$CLJS.H([$CLJS.rO,b]))});
hW.m(null,AW,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=iW(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.tA);$CLJS.Ra(d)||$CLJS.HR.g(d,b)?a=$CLJS.Ne.v($CLJS.EU,a,$CLJS.R,$CLJS.H([$CLJS.nF,b])):(c=$CLJS.Mz($CLJS.Ex),$CLJS.n($CLJS.Lz("metabase.mbql.util",c))&&(b=$CLJS.eD("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.xh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.Kz("metabase.mbql.util",c,$CLJS.xv(),b):$CLJS.Kz("metabase.mbql.util",c,$CLJS.xv.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),hW.h(new $CLJS.P(null,3,5,$CLJS.Q,[AW,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
hW.m(null,$CLJS.zU,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=iW(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,b,$CLJS.R.j(e,$CLJS.rQ,$CLJS.uk.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.zD,c],null),$CLJS.n(d)?$CLJS.Ae([c,d]):null,a])))],null)});
for(var GW=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.dr,$CLJS.fr],null)),HW=null,IW=0,JW=0;;)if(JW<IW){var KW=HW.X(null,JW);hW.m(null,KW,function(){return function(a){return jW(a)}}(GW,HW,IW,JW,KW));JW+=1}else{var LW=$CLJS.y(GW);if(LW){var MW=LW;if($CLJS.vd(MW)){var NW=$CLJS.ic(MW),yna=$CLJS.jc(MW),zna=NW,Ana=$CLJS.D(NW);GW=yna;HW=zna;IW=Ana}else{var OW=$CLJS.z(MW);hW.m(null,OW,function(){return function(a){return jW(a)}}(GW,HW,IW,JW,OW,MW,LW));GW=$CLJS.B(MW);HW=null;IW=0}JW=0}else break}
hW.m(null,$CLJS.PE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PE,iW(a),iW(b)],null),c)});hW.m(null,$CLJS.cB,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);b=$CLJS.B(b);var c=iW(a);a=$CLJS.n(XV($CLJS.hF,a))?$CLJS.EU.l(c,$CLJS.Vj,$CLJS.H([$CLJS.nF])):c;return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cB,a],null),b)});
for(var PW=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.ZE,$CLJS.sF,$CLJS.gF,$CLJS.$E,$CLJS.Gq,$CLJS.yF,$CLJS.Dq,$CLJS.Fq,$CLJS.zq,$CLJS.Bq,$CLJS.KE,$CLJS.RE,$CLJS.IE,$CLJS.UE,$CLJS.JE],null)),QW=null,RW=0,SW=0;;)if(SW<RW){var TW=QW.X(null,SW);hW.m(null,TW,function(){return function(a){return kW(a)}}(PW,QW,RW,SW,TW));SW+=1}else{var UW=$CLJS.y(PW);if(UW){var VW=UW;if($CLJS.vd(VW)){var WW=$CLJS.ic(VW),Bna=$CLJS.jc(VW),Cna=WW,Dna=$CLJS.D(WW);PW=Bna;QW=Cna;RW=Dna}else{var XW=$CLJS.z(VW);hW.m(null,XW,
function(){return function(a){return kW(a)}}(PW,QW,RW,SW,XW,VW,UW));PW=$CLJS.B(VW);QW=null;RW=0}SW=0}else break}hW.m(null,nna,function(){return null});hW.m(null,$CLJS.sL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sL,hW.h(b),a],null)});
hW.m(null,xW,function(a){a=$CLJS.y(a);$CLJS.z(a);a=$CLJS.B(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);c=hW.h;var e=$CLJS.Q;b=hW.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,mna)?new $CLJS.h(null,1,[$CLJS.U,a],null):new $CLJS.h(null,1,[$CLJS.jD,a],null);return c.call(hW,new $CLJS.P(null,3,5,e,[$CLJS.sL,b,a],null))});
for(var YW=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rw,$CLJS.iF],null)),ZW=null,$W=0,aX=0;;)if(aX<$W){var bX=ZW.X(null,aX);hW.m(null,bX,function(){return function(a){return lW(a)}}(YW,ZW,$W,aX,bX));aX+=1}else{var cX=$CLJS.y(YW);if(cX){var dX=cX;if($CLJS.vd(dX)){var eX=$CLJS.ic(dX),Ena=$CLJS.jc(dX),Fna=eX,Gna=$CLJS.D(eX);YW=Ena;ZW=Fna;$W=Gna}else{var fX=$CLJS.z(dX);hW.m(null,fX,function(){return function(a){return lW(a)}}(YW,ZW,$W,aX,fX,dX,cX));YW=$CLJS.B(dX);ZW=null;$W=0}aX=0}else break}
for(var gX=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.bF,$CLJS.DF,$CLJS.ME,$CLJS.wF,$CLJS.tF,$CLJS.ri,$CLJS.Aj,$CLJS.uF,$CLJS.NE],null)),hX=null,iX=0,jX=0;;)if(jX<iX){var kX=hX.X(null,jX);hW.m(null,kX,function(){return function(a){return mW(a)}}(gX,hX,iX,jX,kX));jX+=1}else{var lX=$CLJS.y(gX);if(lX){var mX=lX;if($CLJS.vd(mX)){var nX=$CLJS.ic(mX),Hna=$CLJS.jc(mX),Ina=nX,Jna=$CLJS.D(nX);gX=Hna;hX=Ina;iX=Jna}else{var oX=$CLJS.z(mX);hW.m(null,oX,function(){return function(a){return mW(a)}}(gX,hX,iX,jX,
oX,mX,lX));gX=$CLJS.B(mX);hX=null;iX=0}jX=0}else break}hW.m(null,$CLJS.TE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TE,iW(b),a],null)});
for(var pX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oF,$CLJS.qF],null)),qX=null,rX=0,sX=0;;)if(sX<rX){var tX=qX.X(null,sX);hW.m(null,tX,function(){return function(a){return nW(a)}}(pX,qX,rX,sX,tX));sX+=1}else{var uX=$CLJS.y(pX);if(uX){var vX=uX;if($CLJS.vd(vX)){var wX=$CLJS.ic(vX),Kna=$CLJS.jc(vX),Lna=wX,Mna=$CLJS.D(wX);pX=Kna;qX=Lna;rX=Mna}else{var xX=$CLJS.z(vX);hW.m(null,xX,function(){return function(a){return nW(a)}}(pX,qX,rX,sX,xX,vX,uX));pX=$CLJS.B(vX);qX=null;rX=0}sX=0}else break}
hW.m(null,$CLJS.BF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BF,hW.h(b),hW.h(a)],null)});
hW.m(null,$CLJS.QE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.Yd.g(hW.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QE,b],null)),bW.l(a,$CLJS.H([cW]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QE,$CLJS.yf(function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[hW.h(v),hW.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[hW.h(m),hW.h(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}(b)}())],null)});
hW.m(null,$CLJS.zE,function(a){a=$CLJS.y(a);$CLJS.z(a);var b=$CLJS.B(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);return $CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zE,hW.h(a),$CLJS.E.g(0,b)?1:hW.h(b)],null),$CLJS.cf.g(hW,c))});
var eW=function eW(a){if($CLJS.td(a))return $CLJS.Od(function(d,e,f){return $CLJS.R.j(d,e,eW.h?eW.h(f):eW.call(null,f))},a,a);if($CLJS.sd(a))return $CLJS.gs(a,eW);if($CLJS.n($V(a))){var c=function(){try{return hW.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.Mz($CLJS.Cx);if($CLJS.n($CLJS.Lz("metabase.mbql.normalize",e))){var f=$CLJS.GD("Invalid clause:");f instanceof Error?$CLJS.Kz("metabase.mbql.normalize",e,$CLJS.xv.l($CLJS.H([a])),f):$CLJS.Kz("metabase.mbql.normalize",e,$CLJS.xv.l($CLJS.H([f,
a])),null)}throw $CLJS.Th($CLJS.eD("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.qU(d)])),new $CLJS.h(null,1,[$CLJS.WQ,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.Wf.j($CLJS.Yd.g($CLJS.fd(c),$CLJS.z(c)),$CLJS.cf.h(eW),$CLJS.Hc(c)):c}return $CLJS.yd(a)?$CLJS.ek.g(eW,a):$CLJS.pd(a)?$CLJS.Wf.j($CLJS.fd(a),$CLJS.cf.h(eW),a):a},oW=function oW(a){return function f(d,e){try{if($CLJS.n(function(){var x=UV($CLJS.yd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Z)try{if($CLJS.n(function(){var A=UV($CLJS.be);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Z;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Z)try{if($CLJS.ud(e)&&2<=$CLJS.D(e))try{var k=$CLJS.jk.j(e,0,2);if($CLJS.ud(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.cD($CLJS.be,$CLJS.Oe(new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.Jq,null,$CLJS.Zr,null,$CLJS.CF,null,$CLJS.Iq,null,
xW,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(EW(m)))return $CLJS.Wf.j($CLJS.xf,$CLJS.dD(oW),a);throw $CLJS.Z;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw C;}else throw $CLJS.Z;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Z)try{if($CLJS.ud(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.jk.j(e,0,1);if($CLJS.ud(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Z)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw A;
}else throw d;else throw x;}}($CLJS.xf,a)},yX=function yX(a){return function f(d,e){try{var k=UV($CLJS.yd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Z)try{if($CLJS.ud(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.ce(m,$CLJS.eF)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,t],null))}throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,
$CLJS.YE))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,t],null));throw $CLJS.Z;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,wW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,t],null));throw $CLJS.Z;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Z)try{m=$CLJS.F(e,1);if($CLJS.ce(m,zW))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,t],null));throw $CLJS.Z;}catch(X){if(X instanceof Error)if(m=X,m===
$CLJS.Z)try{var v=$CLJS.F(e,0);if($CLJS.ce(v,wW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,t],null));throw $CLJS.Z;}catch(T){if(T instanceof Error)if(m=T,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,zW))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,t],null));throw $CLJS.Z;}catch(da){if(da instanceof Error)if(m=da,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.eF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eF,iW(t)],null);throw $CLJS.Z;}catch(oa){if(oa instanceof
Error)if(m=oa,m===$CLJS.Z)try{v=$CLJS.F(e,0);if($CLJS.ce(v,$CLJS.YE))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YE,iW(t)],null);throw $CLJS.Z;}catch(Ma){if(Ma instanceof Error){t=Ma;if(t===$CLJS.Z)throw $CLJS.Z;throw t;}throw Ma;}else throw m;else throw oa;}else throw m;else throw da;}else throw m;else throw T;}else throw m;else throw X;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Z)try{if($CLJS.ud(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.jk.j(e,0,0);if($CLJS.ud(x)&&0===$CLJS.D(x))try{var A=$CLJS.jk.g(e,0);if($CLJS.E.g(A,a))return $CLJS.yf($CLJS.vk.h($CLJS.cf.g(yX,a)));throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw K;}else throw $CLJS.Z;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Z)return $CLJS.VV(f,d,e);throw u;}throw K;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.xf,a)},rW=$CLJS.ck.g(eW,function(a){var b=sW($CLJS.XE.h(a))?$CLJS.fk.j(a,$CLJS.XE,cna):a;b=sW($CLJS.WE.h(a))?$CLJS.fk.j(b,$CLJS.WE,dna):b;b=sW($CLJS.PD.h(a))?$CLJS.fk.j(b,$CLJS.PD,$CLJS.Qe($CLJS.ek,gW)):b;b=sW($CLJS.LQ.h(a))?$CLJS.fk.j(b,$CLJS.LQ,yX):b;return sW($CLJS.FP.h(a))?$CLJS.fk.j(b,$CLJS.FP,fna):b}),Nna=new $CLJS.h(null,3,[$CLJS.gP,$CLJS.Pd,$CLJS.DE,new $CLJS.h(null,2,[$CLJS.FP,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.gP);if($CLJS.n(b))return a=
$CLJS.wU(a,new $CLJS.h(null,1,[$CLJS.gP,$CLJS.DE],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.gP],null),a=uW.g?uW.g(a,b):uW.call(null,a,b),$CLJS.wU(a,new $CLJS.h(null,1,[$CLJS.DE,$CLJS.gP],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.DE],null);return uW.g?uW.g(a,b):uW.call(null,a,b)},$CLJS.BM,new $CLJS.h(null,1,[vW,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.DE],null);return uW.g?uW.g(a,b):uW.call(null,a,b)}],null)],null),CW,$CLJS.Pd],null),uW=function uW(a){switch(arguments.length){case 1:return uW.h(arguments[0]);
case 2:return uW.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};uW.h=function(a){return uW.g(a,$CLJS.xf)};uW.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.Bz(Nna,b):null;return $CLJS.id(c)?c.h?c.h(a):c.call(null,a):$CLJS.td(a)?a:$CLJS.sd(a)?jna(a,b):$CLJS.rd(a)?kna(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.Th("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Ci,a,$CLJS.Nk,b],null),d);throw d;}};uW.A=2;
$CLJS.zX=function(){var a=$CLJS.ck.l(uW,ina,gna,$CLJS.H([bW]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.Th($CLJS.eD("Error normalizing query: {0}",$CLJS.H([$CLJS.qU(c)])),new $CLJS.h(null,1,[$CLJS.DE,b],null),c);}throw d;}}}();$CLJS.AX=function AX(a,b){if($CLJS.Ra($CLJS.y(a)))a=(0,$CLJS.zX)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.es(a);b=$CLJS.Ae([$CLJS.ed(a),b]);f=AX.g?AX.g(f,b):AX.call(null,f,b);a=e.call(d,f,$CLJS.ed(a))}return a};